import Swiper from 'swiper/bundle';
import owlCarousel from 'owl.carousel'

/* Swiper Slider */
/* Hero Slider */
const mainSlider = new Swiper('.extreme-slider', {
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.extreme-slider .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.extreme-slider .swiper-button-next',
        prevEl: '.extreme-slider .swiper-button-prev',
    }
});

/* Single Product Thumbnail Carousel */
const productThumbCarousel = new Swiper('.product-thumb-carousel', {
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: {
        el: '.product-thumb-carousel .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.product-thumb-carousel .swiper-button-next',
        prevEl: '.product-thumb-carousel .swiper-button-prev',
    }
});

/* Single Product Image Slider */
const productImageSlider = new Swiper('.product-image-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: '.product-image-slider .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.product-image-slider .swiper-button-next',
        prevEl: '.product-image-slider .swiper-button-prev',
    },
    thumbs: {
        swiper: productThumbCarousel,
    },
});

/* Product Carousel */
const productCarousel = new Swiper('.product-carousel', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 15,
    //centeredSlides: true,
    watchSlidesProgress: true,
    touchStartPreventDefault: false,
    preventClicks: false,
    preventClicksPropagation: false,
    pagination: {
        el: '.product-carousel .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.products-slider-container .swiper-button-next',
        prevEl: '.products-slider-container .swiper-button-prev',
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 15
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 15
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 15
        }
    }
});

$('.feature-pro-active').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    smartSpeed: 1500,
    navText: ['<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-icon svg-inline--fa" role="img" aria-hidden="true"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-icon svg-inline--fa" role="img" aria-hidden="true"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>'],
    margin: 30,
    responsive: {
        0: {
            items: 1,
            autoplay: true,
            smartSpeed: 500
        },
        480: {
            items: 2
        },
        768: {
            items: 2
        },
        992: {
            items: 3
        },
        1200: {
            items: 4
        }
    }
})